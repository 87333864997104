*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
.hoshd:hover,
.up-hor:hover {
  transform: translate(0, -5px);
}
.cta-text,
.v-center {
  align-self: center;
}
.-reviewr,
.form-block,
.hero-section,
.hero-section-1,
.image-div,
.of-hiden {
  overflow: hidden;
}
.btn-link.focus,
.btn-link:focus,
.navbar-toggler:focus {
  text-decoration: none;
  box-shadow: none;
}

/* side scroll bar  */

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #e8e8e8;
}
::-webkit-scrollbar-thumb {
  background: #26bdea;
}
::-webkit-scrollbar-thumb:hover {
  background: #26bdea;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: grayscale;
  box-sizing: border-box;
}
body {
  /* font-family: Lato, sans-serif; */
  font-family: 'Inter', sans-serif;
  color: #000000;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
.animate,
.btn-rd,
.dark-logo,
.hoshd,
.hover-scale img,
.hover-scale:hover,
.steps-div,
.sticky,
.top-header,
.up-hor,
.white-logo,
a {
  -webkit-transition: 1s cubic-bezier(0.17, 0.85, 0.438, 0.99);
  -o-transition: 1s cubic-bezier(0.17, 0.85, 0.438, 0.99);
  transition: 1s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: Roboto, sans-serif; */
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #131238;
}
ul {
  list-style: none;
}
a:hover {
  text-decoration: none;
}
button:focus {
  outline: 0 !important;
}
.v-center {
  align-items: center !important;
}
.text-w {
  color: #fff;
}
.text-b {
  color: #131238;
}
em {
  color: #f47521;
  font-style: inherit;
}
h1 {
  font-size: 45px;
  line-height: 57px;
}
h2 {
  font-size: 40px;
  line-height: 52px;
}
h3 {
  font-size: 34px;
  line-height: 46px;
}
h4 {
  font-size: 22px;
  line-height: 32px;
}
h5 {
  font-size: 35px;
  line-height: 30px;
}
h6 {
  font-size: 16px;
  line-height: 26px;
}
p {
  font-size: 14spx;
  line-height: 28px;
  color: #5d5d5d;
}
.pad-tb {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pr--100 {
  padding-right: 100px;
}
.pr--50,
.pr50 {
  padding-right: 50px;
}
.pl--100 {
  padding-left: 100px;
}
.pl--50 {
  padding-left: 50px;
}
.mr15 {
  margin-right: 15px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
html .pl5 {
  padding-left: 5px !important;
}
html .pt0 {
  padding-top: 0;
}
html .pm0 {
  padding-bottom: 0;
}
html .mt0 {
  margin-top: 0;
}
html .mb0 {
  margin-bottom: 0;
}
html .mt10 {
  margin-top: 10px;
}
html .mt5 {
  margin-top: 5px;
}
html .mt20 {
  margin-top: 20px;
}
html .mt30 {
  margin-top: 30px;
}
html .mt40 {
  margin-top: 40px;
}
html .mt50 {
  margin-top: 50px;
}
html .mt60 {
  margin-top: 60px;
}
html .mt70 {
  margin-top: 70px;
}
html .mt80 {
  margin-top: 80px;
}
html .mt90 {
  margin-top: 90px;
}
html .mt100 {
  margin-top: 100px;
}
html .mb10 {
  margin-bottom: 10px;
}
html .mb5 {
  margin-bottom: 5px;
}
html .mb20 {
  margin-bottom: 20px;
}
html .mb25 {
  margin-bottom: 25px;
}
html .mb30 {
  margin-bottom: 30px;
}
html .mb40 {
  margin-bottom: 40px;
}
html .mb50 {
  margin-bottom: 50px;
}
html .mb60 {
  margin-bottom: 60px;
}
html .mb70 {
  margin-bottom: 70px;
}
html .mb80 {
  margin-bottom: 80px;
}
html .mb90 {
  margin-bottom: 90px;
}
html .mb100 {
  margin-bottom: 100px;
}
html .m0 {
  margin: 0 !important;
}
html .p0 {
  padding: 0 !important;
}
html .pt10 {
  padding-top: 10px;
}
html .pt20 {
  padding-top: 20px;
}
html .pt30 {
  padding-top: 30px;
}
html .pt40 {
  padding-top: 40px;
}
html .pt50 {
  padding-top: 50px;
}
html .pt60 {
  padding-top: 60px;
}
html .pt70 {
  padding-top: 70px;
}
html .pt80 {
  padding-top: 80px;
}
html .pt90 {
  padding-top: 90px;
}
html .pt100 {
  padding-top: 100px;
}
html .pt110 {
  padding-top: 110px;
}
html .pt120 {
  padding-top: 120px;
}
html .pt130 {
  padding-top: 130px;
}
html .pt150 {
  padding-top: 150px;
}
html .pt140 {
  padding-top: 140px;
}
html .pb10 {
  padding-bottom: 10px;
}
html .pb20 {
  padding-bottom: 20px;
}
html .pb30 {
  padding-bottom: 30px;
}
html .pb40 {
  padding-bottom: 40px;
}
html .pb50 {
  padding-bottom: 50px;
}
html .pb60 {
  padding-bottom: 60px;
}
html .pb70 {
  padding-bottom: 70px;
}
html .pb80 {
  padding-bottom: 80px;
}
html .pb90 {
  padding-bottom: 90px;
}
html .pb100 {
  padding-bottom: 100px;
}
html .pb110 {
  padding-bottom: 110px;
}
html .pb120 {
  padding-bottom: 120px;
}
html .pb130 {
  padding-bottom: 130px;
}
html .pb140 {
  padding-bottom: 140px;
}
html .pb150 {
  padding-bottom: 150px;
}
.hoshd:hover {
  box-shadow: 0 15px 39px -11px #46638e45;
}
.hover-scale img {
  transform: scale(1);
}
.hover-scale:hover img {
  transform: scale(1.05);
}
.icon-bar {
  width: 24px;
  height: 3px;
  background-color: #fff;
  display: block;
  transition: all 0.2s;
  margin-top: 4px;
}
.sticky .icon-bar {
  background-color: #000;
}
.navbar-toggler {
  padding: 0;
  border: none;
  background: 0 0;
  position: relative;
  top: -3px;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.navbar-toggler .middle-bar {
  opacity: 0;
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
.navbar-toggler.collapsed .bottom-bar,
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.hide-desk {
  display: none;
}
.mobile-btn {
  color: #fff;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 0 !important;
}
.sticky .mobile-btn {
  color: #000;
}
.btn-call span.clltxt {
  display: block;
  font-size: 13px;
  font-weight: 400;
}
.btn-call i {
  width: 34px;
  height: 34px;
  background-color: #20bfee;
  border-radius: 50%;
  text-align: center;
  z-index: 99;
  line-height: 34px;
  margin-right: 10px;
  color: #fff;
}
.btn-call {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}
.btn-call:hover {
  color: #fff !important;
}
.sticky .btn-call:hover {
  color: #131238 !important;
}
.navbar-brand .white-logo {
  display: inline-block;
}
.navbar-brand .dark-logo {
  display: none;
}
.sticky .navbar-brand .dark-logo {
  display: inline-block;
}
.sticky .navbar-brand .white-logo {
  display: none;
}
.top-header .sticky .nav-link.active {
  color: #673ab7;
}
.top-header .sticky .nav-link.active:after {
  background: #673ab7;
}
.top-header .sticky .nav-link {
  color: #131238;
}
.top-header .sticky {
  background: #fff;
  -webkit-box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.08);
  box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.08);
}
.top-header .nav-link:hover .nav-number {
  color: #26bdea;
}
.top-header .nav-link {
  font-weight: 600;
  font-size: 17px;
  color: #fff;
}
.top-header .nav-link.active {
  color: #fff;
}
.top-header .nav-link.active:after {
  transition: all 0.2s linear 0s;
  background: #22bdef;
  width: 90%;
}
.top-header .nav-link:after {
  content: "";
  display: block;
  height: 2px;
  text-align: center;
  left: 0;
  right: 0;
  width: 0;
  float: none;
  background: #26bdea;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  margin: 0 auto;
  position: relative;
  bottom: -4px;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding-top: 0;
  padding-bottom: 0;
  height: 70px;
}
.brand-logo a {
  display: block;
}
.header-right ul li:after {
  content: "";
  position: absolute;
  border-right: 1px solid #eee;
  height: 30px;
  right: -12px;
  top: 10px;
}
.header-right ul li:last-child:after {
  content: "";
  position: absolute;
  border-right: 0 solid #eee;
}
.car-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  clip-path: ellipse(120% 62% at 50% 40%);
  left: 0;
  top: 0;
  max-height: 960px;
  min-height: initial;
  width: 100vw;
  max-width: 100%;
  z-index: 0;
  background-image: radial-gradient(
      circle at 46% 76%,
      rgba(255, 255, 255, 0.03) 0,
      rgba(255, 255, 255, 0.03) 3%,
      transparent 3%,
      transparent 100%
    ),
    radial-gradient(
      circle at 36% 87%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 3%,
      transparent 3%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 78%,
      rgba(255, 255, 255, 0.01) 0,
      rgba(255, 255, 255, 0.01) 3%,
      transparent 3%,
      transparent 100%
    ),
    radial-gradient(
      circle at 22% 99%,
      rgba(255, 255, 255, 0.03) 0,
      rgba(255, 255, 255, 0.03) 7%,
      transparent 7%,
      transparent 100%
    ),
    radial-gradient(
      circle at 38% 63%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 7%,
      transparent 7%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 50%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 7%,
      transparent 7%,
      transparent 100%
    ),
    radial-gradient(
      circle at 93% 55%,
      rgba(255, 255, 255, 0.01) 0,
      rgba(255, 255, 255, 0.01) 7%,
      transparent 7%,
      transparent 100%
    ),
    radial-gradient(
      circle at 24% 100%,
      rgba(255, 255, 255, 0.01) 0,
      rgba(255, 255, 255, 0.01) 7%,
      transparent 7%,
      transparent 100%
    ),
    radial-gradient(
      circle at 26% 53%,
      rgba(255, 255, 255, 0.03) 0,
      rgba(255, 255, 255, 0.03) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 17% 5%,
      rgba(255, 255, 255, 0.03) 0,
      rgba(255, 255, 255, 0.03) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 53% 51%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 10% 25%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 31% 56%,
      rgba(255, 255, 255, 0.01) 0,
      rgba(255, 255, 255, 0.01) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 40% 69%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 33% 89%,
      rgba(255, 255, 255, 0.02) 0,
      rgba(255, 255, 255, 0.02) 5%,
      transparent 5%,
      transparent 100%
    ),
    radial-gradient(
      circle at 96% 22%,
      rgba(255, 255, 255, 0.03) 0,
      rgba(255, 255, 255, 0.03) 5%,
      transparent 5%,
      transparent 100%
    ),
    linear-gradient(296deg, #510093, #487bf4);
}
.hero-section {
  position: relative;
  height: 90vh;
  display: grid;
  align-items: center;
}
.img-box1 img {
  border-radius: 20px 100px 20px 20px;
}
.header-heading {
  margin: 0 50px 0 0;
}
.header-heading h1 {
  color: #fff;
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  margin-bottom: 30px;
}
.header-heading p {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
  font-weight: 500;
}
.btn-rd,
.btn-rd:hover,
.info-text-block h5 a {
  color: #131238;
}
.card-icon-section {
  width: 80px;
  margin: 0 auto 30px;
}
.btm-sldrdiv {
  margin-top: -100px;
}
.border-outer {
  padding: 40px;
  border: 1px solid #673ab7;
  border-radius: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0 12px 18px, rgba(0, 0, 0, 0.06) 0 1px 12px;
}
.owl-carousel .owl-item img {
  display: block;
  width: inherit;
}
.btn-rd,
.owl-carousel .owl-dots,
.owl-carousel .owl-dots .owl-dot,
.social-links- a {
  display: inline-block;
}
.owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
  background: #26bdea;
  transform: translate3d(0, -50%, 0) scale(0.7);
  transition: all 250ms ease-out 0s;
}
.owl-carousel .owl-dots {
  width: 100%;
  text-align: center;
  margin: 40px 0 0;
  position: absolute;
  transition: all 250ms ease-out 0s;
}
.owl-carousel .owl-dots .owl-dot span {
  background: #26bdea;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0, -50%, 0) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
  border-radius: 6px;
}
.about-bg {
  background-image: linear-gradient(
      90deg,
      rgba(165, 165, 165, 0.03) 0,
      rgba(165, 165, 165, 0.03) 8%,
      rgba(235, 235, 235, 0.03) 8%,
      rgba(235, 235, 235, 0.03) 9%,
      rgba(7, 7, 7, 0.03) 9%,
      rgba(7, 7, 7, 0.03) 14%,
      rgba(212, 212, 212, 0.03) 14%,
      rgba(212, 212, 212, 0.03) 17%,
      rgba(219, 219, 219, 0.03) 17%,
      rgba(219, 219, 219, 0.03) 95%,
      rgba(86, 86, 86, 0.03) 95%,
      rgba(86, 86, 86, 0.03) 100%
    ),
    linear-gradient(
      67.5deg,
      rgba(80, 80, 80, 0.03) 0,
      rgba(80, 80, 80, 0.03) 11%,
      rgba(138, 138, 138, 0.03) 11%,
      rgba(138, 138, 138, 0.03) 17%,
      rgba(122, 122, 122, 0.03) 17%,
      rgba(122, 122, 122, 0.03) 24%,
      rgba(166, 166, 166, 0.03) 24%,
      rgba(166, 166, 166, 0.03) 27%,
      rgba(245, 245, 245, 0.03) 27%,
      rgba(245, 245, 245, 0.03) 89%,
      rgba(88, 88, 88, 0.03) 89%,
      rgba(88, 88, 88, 0.03) 100%
    ),
    linear-gradient(
      67.5deg,
      rgba(244, 244, 244, 0.03) 0,
      rgba(244, 244, 244, 0.03) 4%,
      rgba(16, 16, 16, 0.03) 4%,
      rgba(16, 16, 16, 0.03) 10%,
      rgba(157, 157, 157, 0.03) 10%,
      rgba(157, 157, 157, 0.03) 20%,
      rgba(212, 212, 212, 0.03) 20%,
      rgba(212, 212, 212, 0.03) 83%,
      rgba(5, 5, 5, 0.03) 83%,
      rgba(5, 5, 5, 0.03) 84%,
      rgba(237, 237, 237, 0.03) 84%,
      rgba(237, 237, 237, 0.03) 100%
    ),
    linear-gradient(90deg, #fff8f5, #fcf3ff);
}
.itm-media-object .media img {
  width: 100%;
}
.itm-media-object .media .img-ab- {
  background: #f5f5f5;
  border-radius: 20px;
  width: 65px;
  padding: 10px;
}
.itm-media-object .media-body {
  margin-left: 20px;
}
.itm-media-object h4 {
  text-align: left;
  margin-bottom: 10px;
}
.bg-fixed-img:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-image: radial-gradient(
    circle at bottom right,
    #34218d 0,
    #34218d 20%,
    #3432a8 20%,
    #3432a8 40%,
    #3444c3 40%,
    #3444c3 60%,
    #3455dd 60%,
    #3455dd 80%,
    #3466f8 80%,
    #3466f8 100%
  );
  opacity: 0.6;
  z-index: 1;
}
.bg-fixed-img,
.btn-rd,
.reviews-card {
  position: relative;
}
.bg-fixed-img {
  background-attachment: fixed;
}
.btn-rd {
  /* font-family: source sans pro, sans-serif; */
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 600;
  padding: 0 32px;
  line-height: 56px;
  text-align: center;
  outline: 0;
  background-color: #ffd064;
  border: none;
  -webkit-border-radius: 12px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 15px 0 rgb(48 36 0/10%);
  box-shadow: 0 10px 15px 0 rgb(48 36 0/10%);
}
.mt20 {
  margin-top: 12px;
}
.image-div {
  -webkit-box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3.4px 2.7px -30px rgba(0, 0, 0, 0.059),
    0 8.2px 8.9px -30px rgba(0, 0, 0, 0.071),
    0 25px 40px -30px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.form-block,
.reviews-card {
  -webkit-box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
}
.info-text-block {
  padding: 20px 0 0;
}
.info-text-block h5 {
  margin: 0 0 5px;
}
.info-text-block p {
  color: #4d42c7;
}
.social-links- {
  margin: 20px 0 0;
}
.social-links- a {
  color: #0a2233;
  line-height: 1;
  font-size: 15px;
  border: 1px solid #e8e8ef;
  padding: 8px;
  width: 32px;
  height: 32px;
  text-align: center;
  margin: 0 10px 0 0;
  border-radius: 12px;
}
.review-bg {
  background-image: linear-gradient(
      23deg,
      rgba(202, 202, 202, 0.02) 0,
      rgba(202, 202, 202, 0.02) 13%,
      transparent 13%,
      transparent 80%,
      rgba(11, 11, 11, 0.02) 80%,
      rgba(11, 11, 11, 0.02) 100%
    ),
    linear-gradient(
      42deg,
      rgba(98, 98, 98, 0.02) 0,
      rgba(98, 98, 98, 0.02) 36%,
      transparent 36%,
      transparent 77%,
      rgba(252, 252, 252, 0.02) 77%,
      rgba(252, 252, 252, 0.02) 100%
    ),
    linear-gradient(
      286deg,
      rgba(173, 173, 173, 0.02) 0,
      rgba(173, 173, 173, 0.02) 2%,
      transparent 2%,
      transparent 12%,
      rgba(59, 59, 59, 0.02) 12%,
      rgba(59, 59, 59, 0.02) 100%
    ),
    linear-gradient(
      77deg,
      rgba(87, 87, 87, 0.02) 0,
      rgba(87, 87, 87, 0.02) 18%,
      transparent 18%,
      transparent 55%,
      rgba(247, 247, 247, 0.02) 55%,
      rgba(247, 247, 247, 0.02) 100%
    ),
    linear-gradient(90deg, rgb(232 236 255), rgb(255 253 246));
}
.reviews-card span {
  font-size: 30px;
  color: #26bdea;
}
.reviews-card {
  text-align: center;
  background: #fff;
  border-radius: 20px;
  /* padding: 30px; */
  margin: 5px 0;
  box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  top: 0;
  border: 1px solid #e8e8ef;
  cursor: grab;
  background-image: repeating-linear-gradient(
      90deg,
      hsla(196, 0%, 79%, 0.06) 0,
      hsla(196, 0%, 79%, 0.06) 1px,
      transparent 1px,
      transparent 96px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(196, 0%, 79%, 0.06) 0,
      hsla(196, 0%, 79%, 0.06) 1px,
      transparent 1px,
      transparent 96px
    ),
    repeating-linear-gradient(
      0deg,
      hsla(196, 0%, 79%, 0.09) 0,
      hsla(196, 0%, 79%, 0.09) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      90deg,
      hsla(196, 0%, 79%, 0.09) 0,
      hsla(196, 0%, 79%, 0.09) 1px,
      transparent 1px,
      transparent 12px
    ),
    linear-gradient(90deg, #fff, #fff);
}
.-reviewr,
.imgbdr,
.reviews-card img {
  border-radius: 10px 10px 2px 0px;
}
.reviews-card img {
  /* max-width: 140px; */
  /* margin: 0 auto 15px; */
}
.reviews-card h5 span {
  font-weight: 400;
  display: block;
  font-size: 16px;
  margin: 0 0 10px;
  color: #31abfc;
  /* font-family: Assistant, sans-serif; */
  font-family: 'Inter', sans-serif;
}
.reviewer-text {
  text-align: left;
}
.-reviewr {
  width: 70px;
  height: 70px;
  margin-right: 15px;
}
.review-text {
  text-align: justify;
  padding-bottom: 30px;
}
.footer- p,
.form-block p.trm,
.form-header,
.form-header p {
  text-align: center;
}
.cta-heading-2 h2,
.cta-heading-2 p {
  padding-bottom: 20px;
}
.star-rate {
  margin-top: 5px;
}
.star-rate li {
  display: inline-block;
  margin: 0 2px;
}
.star-rate li a.chked {
  color: #ff711c;
}
.star-rate li a {
  color: #6a6a8e;
}
.-client-details- {
  display: flex;
}
.help-block {
  color: #ff5050;
  margin: 0 0 0 10px;
  font-size: 12px;
  position: absolute;
  bottom: 11px;
  background: #fff;
  right: 24px;
}
.cta-heading-2 img {
  margin: 0 0 20px;
}
.cta-heading-2 {
  margin-right: 50px;
}
.cta-heading-2 h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 1.2;
  color: #0a2233;
}
#contact-form .form-group {
  margin-bottom: 0;
  position: relative;
}
.custom-control-label::after,
.custom-control-label::before {
  position: absolute;
  content: "";
  display: block;
}
.form-group {
  margin-bottom: 0;
}
.form-block {
  background: rgba(255, 255, 255, 0.85);
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  border: 1px solid #e8e8ef;
}
.form-block input,
.form-block select,
.form-block textarea {
  width: 100%;
  margin: 0 0 10px;
  color: #172b43;
  font-size: 16px;
  background: #fff;
  -webkit-box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  outline: 0;
}
.form-block form {
  padding: 0 15px 0px;
}
.form-block input:focus,
.form-block textarea:focus {
  border: 1px solid #26bdea;
}
.form-block input,
.form-block select {
  /* height: 50px; */
  padding: 0 15px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  border: 1px solid #e6e6e6;
}
.form-block textarea {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  border: 1px solid #e6e6e6;
  max-height: 100px !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #26bdea;
  background-color: #26bdea;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
  box-shadow: 0 3px 6px rgba(91, 130, 148, 0.08);
}
.servicecard,
.shadows {
  -webkit-box-shadow: 0 6px 16px rgb(52 105 203/16%);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffbc80;
}
.custom-control-label::before {
  top: -1px;
  left: -1.5rem;
  width: 20px;
  height: 20px;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  top: -3px;
  left: -26px;
  width: 25px;
  height: 25px;
  background: 50%/50% 50% no-repeat;
}
.custom-control {
  margin-bottom: 10px;
}
.form-block label input {
  height: 20px;
  width: 20px;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
}
.fieldsets label {
  position: relative;
  top: -10px;
  font-size: 14px;
  color: #26bdea;
  margin: 0;
  padding: 0;
}
.custom-control label {
  text-indent: -21px;
  padding: 0 0 0 21px;
  font-size: 13px;
}
.form-block label a {
  color: #000;
}
.form-header {
  border-bottom: 3px solid #4877f1;
  padding: 20px 0;
  margin: 0 0 20px;
  background-color: #f8f6ff;
}
.form-header h2 span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #0a2233;
}
.footer- p,
footer,
footer p {
  color: #fff;
}
.form-block p.trm {
  font-size: 13px;
  width: 100%;
  margin: 10px 0 0;
}
.form-block p.trm i {
  padding: 5px 5px 0 0;
}
.pl0 {
  padding-left: 0 !important;
}
footer {
  background-image: linear-gradient(
      0deg,
      transparent 0,
      transparent 60%,
      rgba(183, 183, 183, 0.05) 60%,
      rgba(183, 183, 183, 0.05) 93%,
      transparent 93%,
      transparent 100%
    ),
    linear-gradient(
      135deg,
      transparent 0,
      transparent 55%,
      rgba(183, 183, 183, 0.05) 55%,
      rgba(183, 183, 183, 0.05) 84%,
      transparent 84%,
      transparent 100%
    ),
    linear-gradient(
      0deg,
      transparent 0,
      transparent 80%,
      rgba(183, 183, 183, 0.05) 80%,
      rgba(183, 183, 183, 0.05) 94%,
      transparent 94%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(16 0 43), rgb(16 0 43));
  padding: 25px 0 25px;
}
.footer- a img {
  width: 100px;
}
.footer- p {
  padding: 25px 0 0;
  font-size: 15px;
  font-weight: 500;
}
.footer- a {
  display: block;
}
.footer-block {
  padding: 20px 0;
}
.social-links {
  text-align: right;
}
.social-links a {
  color: #0a2233;
  font-size: 25px;
  line-height: 1;
}
.footer-address-list li i {
  margin: 0 5px 0 0;
}
.copyright {
  border-top: 1px solid rgb(255 255 255/0.1);
  margin-top: 30px;
}
.footer-address-list li {
  font-size: 16px;
  text-indent: -12px;
  padding-left: 25px;
  line-height: 35px;
}
.footer-social-media-icons a {
  color: #fff;
  font-size: 16px;
  border: 1px solid #cacaca;
  border-radius: 12px;
  margin: 0 2px;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 34px;
}
.footer-social-media-icons a.facebook {
  background: #0069f7;
  border-color: #0069f7;
}
.footer-social-media-icons a.twitter {
  background: #00c6f7;
  border-color: #00c6f7;
}
.footer-social-media-icons a.instagram {
  background: #d62977;
  border-color: #d62977;
}
.footer-social-media-icons a.linkedin {
  background: #0e72a3;
  border-color: #0e72a3;
}
.footer-address-list li a {
  color: #fff;
}
.car-bg2 {
  background-image: radial-gradient(
    circle at top left,
    #354ecd 0,
    #354ecd 3%,
    #3548c2 3%,
    #3548c2 20%,
    #3641b7 20%,
    #3641b7 65%,
    #363bac 65%,
    #363bac 72%,
    #3735a0 72%,
    #3735a0 79%,
    #372f95 79%,
    #372f95 82%,
    #38288a 82%,
    #38288a 90%,
    #38227f 90%,
    #38227f 100%
  );
}
.media-card + .media-card {
  margin-top: 30px;
}
.steps-div {
  padding: 30px;
  border-radius: 20px;
}
.steps-div.sd1 {
  background: #f8effa;
}
.steps-div.sd2 {
  background: #fee4cb;
}
.steps-div.sd3 {
  background: #b8f1d0;
}
.steps-div.sd4 {
  background: #ffd3e2;
}
.steps-icons {
  text-align: right;
}
.steps-icons img {
  width: 80px;
}
.review-bg2 {
  background-image: linear-gradient(
    45deg,
    rgb(227 218 255) 0,
    rgb(232 225 255) 21%,
    rgb(236 234 255) 21%,
    rgb(234 232 255) 30%,
    rgb(226 231 255) 30%,
    rgb(241 243 255) 35%,
    rgb(226 237 255) 35%,
    rgb(248 251 255) 51%,
    rgb(248 252 255) 51%,
    rgb(239 248 255) 72%,
    rgb(247 245 255) 72%,
    rgb(243 242 255) 81%,
    rgb(237 238 255) 81%,
    rgb(236 253 255) 100%
  );
}
.step-bg {
  background-image: linear-gradient(268deg, #fff 0, transparent 66%),
    repeating-linear-gradient(
      223deg,
      rgba(189, 189, 189, 0.08) 0,
      rgba(189, 189, 189, 0.08) 1px,
      transparent 1px,
      transparent 16px
    ),
    repeating-linear-gradient(
      268deg,
      rgba(189, 189, 189, 0.08) 0,
      rgba(189, 189, 189, 0.08) 1px,
      transparent 1px,
      transparent 16px
    ),
    repeating-linear-gradient(
      313deg,
      rgba(189, 189, 189, 0.08) 0,
      rgba(189, 189, 189, 0.08) 1px,
      transparent 1px,
      transparent 16px
    ),
    linear-gradient(178deg, #fff, #fff);
}
.accordion .acc-icon:after,
.accordion .card-title:after {
  font-family: "font awesome 4 free";
  font-family: 'Inter', sans-serif;
  position: absolute;
  font-size: 14px;
  right: 5px;
}
.accordion .acc-icon.collapsed:after {
  content: "+";
  font-weight: 900;
  font-size: 20px;
}
.accordion .acc-icon:after {
  content: "-";
  font-weight: 900;
  font-size: 20px;
  top: 14px;
  right: 10px;
  color: #050748;
}
.btn-rd2:after,
.btn-rd3:after {
  content: "→";
  top: 0;
  transition: 0.5s;
  height: 8px;
  position: relative;
}
.card-2 .card-header {
  padding: 10px 20px 10px 0;
  border: 0;
  position: relative;
  background: 0 0;
}
.card-2 .card-header button {
  border: 0;
  color: #050748;
  font-size: 18px;
  font-weight: 600;
}
.btn-rd2,
.btn-rd2:hover,
.text-white a,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.accordion .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 15px 15px;
}
.form-group {
  position: relative;
}
#msgSubmit {
  font-size: 15px;
  margin: 10px 0 0;
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
.footer-ft {
  text-align: center;
  margin-top: 20px;
}
.pora-bg1 {
  background: #0ac0f7;
}
.pora-bg2 {
  background: linear-gradient(90deg, #0ac0f7 0, #9340fa 100%);
}
.shadows {
  box-shadow: 0 6px 16px rgb(52 105 203/16%);
}
.noshadow {
  box-shadow: none !important;
}
.b-radius14 {
  border-radius: 14px;
}
.card-2 .card-header button {
  padding: 0 20px 0 0;
}
.btnpora {
  flex: 0 0 auto;
  /* font-family: source sans pro, sans-serif; */
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 0 32px;
  line-height: 56px;
  text-align: center;
  outline: 0;
  border: none;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  display: inline-flex;
  position: relative;
  cursor: pointer;
}
.btn-rd2 {
  background: linear-gradient(200deg, #b0ed93 0.33%, #5eb5d0 85.46%);
  box-shadow: 0 15px 30px -15px rgb(143 203 215 / 52%);
}
.btn-rd3,
.btn-rd3:hover,
.top-header.th2 .nav-link {
  color: #131238;
}
.btn-rd2:hover:after {
  opacity: 1;
  right: 0;
  width: 2px;
  margin-left: 10px;
}
.btn-rd2:after {
  opacity: 0;
  right: -20px;
  width: 0;
}
.btn-rd3 {
  background-color: #ffd064;
  -webkit-box-shadow: 0 10px 15px 0 rgb(255 193 7/20%);
  box-shadow: 0 10px 15px 0 rgb(255 193 7/20%);
}
.imgbdr,
.servicecard {
  /* background: #fff;
  text-align: center; */
}
.btn-rd3:hover:after {
  opacity: 1;
  right: 0;
  width: 2px;
  margin-left: 10px;
}
.btn-rd3:after {
  opacity: 0;
  right: -20px;
  width: 0;
}
.hero-section-1 {
  position: relative;
  height: auto;
  display: grid;
  align-items: center;
  padding-top: 100px;
}
@media (min-width: 1200px) {
  .ct1280 .container,
  .ct1280 .container-lg,
  .ct1280 .container-md,
  .ct1280 .container-sm,
  .ct1280 .container-xl {
    max-width: 1280px;
  }
}
.fw3 {
  font-weight: 300;
}
.servicecard {
  padding: 15px;
  border-radius: 25px;
  border-left: 1px solid #24bced;
  border-top: 1px solid #24bced;
  border-bottom: 5px solid #24bced;
  border-right: 1px solid #24bced;
  /* box-shadow: 0 10px 20px rgb(36 190 232 / 36%); */
}
.servicecard p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  /* margin-top: 14px; */
}
.servicecard img {
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
}
.service-card {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 25px;
  padding: 20px 0;
}
.icon-pora {
  width: 50px;
  margin-right: 15px;
}
.hero-feature {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 30px;
  margin-top: 40px;
}
.whyus {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 35px;
}
.whyusbox {
  text-align: center;
}
.whyusbox img {
  width: 100%;
}
.imgbdr {
  width: 130px;
  /* height: 90px; */
  margin: 0 auto 15px;
  /* border: 1px solid #eaf0fa;
  padding: 15px; */
}
.subhead {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 15px;
  background: rgb(255 255 255/40%);
  border-radius: 100px;
  padding: 5px 30px;
}
.divrightbdr .steps-div,
.partnerlogo a {
  /* border: 1px solid #dee9ff; */
  background: #fff;
  border-left: 1px solid #24bced;
  border-top: 1px solid #24bced;
  border-bottom: 5px solid #24bced;
  border-right: 1px solid #24bced;
}
.steps-icons-1 img {
  width: 60px;
  margin-bottom: 20px;
}
.divrightbdr .steps-div:hover {
  -webkit-box-shadow: 0 6px 16px rgb(52 105 203/16%);
  box-shadow: 0 6px 16px rgb(52 105 203/16%);
}
.partnerlogo {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
}
.partnerlogo a {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partnerlogo a img {
  max-width: 100%;
  max-height: 45px;
}
.bganimate {
  background-size: 400% 400%;
  animation: poraation 10s ease infinite;
}
@-webkit-keyframes poraation {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@-moz-keyframes poraation {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes poraation {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.overallrating li {
  display: inline-flex;
}
.overallrating li a {
  font-size: 20px;
  background: #ff9800;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  margin-right: 10px;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.25rem;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  color: #050748;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
.blur-bg-set,
.servicecard {
  overflow: hidden;
}
.reviews-section .owl-carousel .owl-dots {
  margin: 20px 0 0;
}
.th2 .btn-call:hover {
  color: #131238 !important;
}
.th2 .mobile-btn {
  color: #131238;
  font-weight: 600;
  margin-right: 10px;
}
.servicecard {
  position: relative;
  background-color: #fff;
  text-align: center;
}
.servicecard:hover:after {
  width: 100%;
}
.servicecard:hover {
  /* content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  height: 4px;
  background: linear-gradient(200deg, #abeb93 0.33%, #25bcf0 85.46%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease; */
  background-color: #22beeb54;
}
.servicecard a {
  display: block;
}
.blur-bg-blocks {
  overflow-x: hidden;
  overflow-y: hidden;
}
.blur-bg {
  z-index: 0;
  border-radius: 50%;
  position: absolute;
  width: 500px;
  height: 500px;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  opacity: 20%;
}
.blur-bg-a {
  left: -4%;
  top: -10%;
  background: #ffb600;
}
.blur-bg-b {
  background: #e04446;
  margin: 0 auto;
  left: -20%;
  top: 30%;
}
.blur-bg-c {
  right: 0;
  top: -10%;
  background: #00ffb8;
}
.navbar-toggler:focus {
  outline: 0;
}
.border-outer {
  position: relative;
  z-index: 100px;
}
.top-header .nav-link:hover {
  color: #ffd064;
}
.getmob,
.th2 .getmob:hover {
  color: #fff !important;
}
.modal .modal-dialog-aside {
  width: 500px;
  max-width: 80%;
  height: 100%;
  margin: 0;
  right: -17px;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 0;
  border-radius: 0;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
.icon-setss {
  display: flex;
  justify-content: space-between;
}
.icon-rows {
  padding: 0;
  text-align: center;
}
.icon-imgg img {
  width: 42px;
}
.icon-txt p {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}
.border0 {
  border: none !important;
}
.form-btm-set {
  padding: 0 20px;
}
.owl-carousel .owl-item .project-logo img {
  display: block;
  width: auto;
  height: 45px;
  margin: 0 auto;
}
.cta-heading {
  position: relative;
  z-index: 5;
}
.getmob {
  background: linear-gradient(200deg, #0ac0f7 0.33%, #9340fa 85.46%);
  box-shadow: 0 15px 30px -15px rgb(90 92 248/60%);
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
}
.slick-initialized .slick-slide {
  display: block;
  padding: 10px !important;
}
.slick-prev:before,
.slick-next:before {
  /* font-family: 'slick'; */
  font-family: 'Inter', sans-serif;
  font-size: 28px !important;
  line-height: 1;
  opacity: 0.75;
  color: #ff6b6b !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0 !important;
}
.btn-rd123 {
  /* font-family: source sans pro, sans-serif; */
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 600;
  padding: 0px 1px;
  line-height: 49px;
  text-align: center;
  outline: 0;
  background-color: #ffd064;
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 15px 0 rgb(48 36 0/10%);
}
.btn-rd1234 {
  /* font-family: source sans pro, sans-serif; */
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 0px 1px;
  line-height: 49px;
  text-align: center;
  outline: 0;
  color: #fff;
  background-color: #26bdea;
  border: none;
  border-radius: 8px;
  box-shadow: 0 10px 15px 0 rgb(48 36 0/10%);
}
.planpad{
  padding: 45px;
}
.planpad1{
  padding: 45px;
}
.logowid{
  width: 180px;
  padding: 5px;
}


@media (max-width: 768px) {
  .displayst {
    display: none;
  }
  .archivecount{
    color: rgb(255, 116, 0);
      font-size: 25px !important;
      padding: 10px !important;
  }
  .logowid{
    width: 135px !important;
  }
  .planpad{
    padding: 10px !important;
  }
  .planpad1{
    padding: 25px !important;
  }
  .footp{
    font-size: 11px;
  }
  .realestatehead{
    padding: 0px;
    font-size: 10px;
}
  .realestatefilterst{
    font-size: 10px;
}
}
@media (max-width: 760px) {
  .displayst {
    display: none;
  }
}
.form-control1 {
  display: block;
  width: 100%;
  height: 34px;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* nav styles */

.navbar-collapse {
  display: block;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.navigation {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation > li {
  display: inline-block;
}

.navigation > li > a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.navigation ul {
  display: none;
  position: absolute;
  /* top: 100%; */
  /* left: 0; */
  background-color: #ffffff;
  padding: 0;
}

/* .navigation li:hover > ul {
  display: block;
  margin-left: 1px;
  background: #ffffff;
  color: #000;
} */
.navigation li:hover > ul {
  display: block;
  background: #ffffff;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  transition: all 0.2s ease;
  /* z-index: 99; */
  background-color: #fff;
  transition: opacity 200ms ease-in, transform 200ms ease-in,
    visibility 200ms ease-in;
}

.navigation ul li {
  display: block;
  /* padding: 10px 0px 10px 0px; */
  font-weight: 600;
  border-bottom: 1px solid rgb(216, 216, 216);
}
.navigation ul li a:hover {
  color: #22bded !important;
  /* background: red; */
}

.navigation ul li a {
  padding: 10px;
  color: #000;
  text-decoration: none;
}

.navigation ul ul {
  top: 0;
  left: 100%;
  width: 200%;
}
.realestatefeatures {
  font-size: 10px;
  margin-top: -35px;
  color: #000 !important;
  font-weight: 600;
}
.carousalbtn {
  font-size: 20px;
  background: #23beec;
  border-radius: 50%;
  padding: 10px;
}
.text-primary {
  color: #009cc7 !important;
}
.card {
  box-shadow: rgb(0 0 0 / 23%) 0px 5px 15px !important;
}
.filbtn {
  padding: 3px;
  width: 65px;
}
.textbot {
  padding: 10px;
  border-bottom: 3px solid;
  border-image: linear-gradient(to right, #abeb93, #25bcf0) 1;

  /* height: 4px;
  background: linear-gradient(200deg, #abeb93 0.33%, #25bcf0 85.46%);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease; */
}

/* input range */

/* === range theme and appearance === */
input[type="range"] {
	font-size: 1.5rem;
	width: 12.5em;
}

input[type="range"] {
	color: #46d4ff;
	--thumb-height: 23px;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 180%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}

input[type="range"].win10-thumb {
	color: #2b2d42;

	--thumb-height: 1.375em;
	--thumb-width: 0.5em;
	--clip-edges: 0.0125em;
}

@media (prefers-color-scheme: dark) {
	html {
		background-color: #000;
	}

	html::before {
		background: radial-gradient(circle at center, #101112, #000);
	}

	input[type="range"] {
		color: #46d4ff;
		--track-color: rgba(255, 255, 255, 0.1);
	}

	input[type="range"].win10-thumb {
		color: #3a86ff;
	}
}

/* === range commons === */
input[type="range"] {
	position: relative;
	background: #fff0;
	overflow: hidden;
}

input[type="range"]:active {
	cursor: grabbing;
}

input[type="range"]:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	position: relative;
}

input[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
		50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(
		100% -1px,
		var(--clip-edges) -1px,
		0 var(--clip-top),
		-100vmax var(--clip-top),
		-100vmax var(--clip-bottom),
		0 var(--clip-bottom),
		var(--clip-edges) 100%,
		var(--clip-further) var(--clip-further)
	);
}

input[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	background: #fff0;
}

input[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
	cursor: not-allowed;
}


/* forms */

form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .select {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea {
  height: 70px;
}
.input-data input:focus {
  outline: none;
}
.select-data select:focus {
  outline: none;
}

.input-data input,
select,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 14px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}

.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
}
.textarea textarea {
  resize: none;
  padding-top: 10px;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 0px;
  font-size: 13px;
  transition: all 0.3s ease;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 90%;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}
.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}
.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }
  .container form {
    padding: 10px 0 0 0;
  }
  .container form .form-row {
    display: block;
  }
  form .form-row .input-data {
    margin: 35px 0 !important;
  }
  .submit-btn .input-data {
    width: 40% !important;
  }
}

/* inputbars */
.input-range__label-container {
  left: -50%;
  position: relative;
  display: none;
}
.input-range__track--active {
  background: #25bcec !important;
}
.input-range__slider {
  background: #24bdf2 !important;
  border: 1px solid #24bdf2 !important;
}

.headst {
  opacity: 0.8;
  background-image: linear-gradient(60deg, #25bcec, #5e5c622b);
  padding: 50px;
}
/* tabstyle */


#h4-container {
  width: 400px;
  max-width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;

}
#h4-container #h4-subcontainer {
  width: 100%;
  position: relative;
 
}
#h4-container #h4-subcontainer  h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  color: #fff !important;
  font-size: 12px;
  transform-origin: center -10px;
  transform: translateX(-50%);
  transition: margin-top 0.15s ease-in-out, opacity 0.15s ease-in-out;
  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #1597ff;
    border-radius: 0 50% 50% 50%;
    transform: rotate(45deg);
    z-index: -1;
  }
}
input:not(:active) + #h4-container h4 {
  opacity: 0;
  margin-top: -50px;
  pointer-events: none;
}
.unselected {
  text-align: center;
  background-color: #f8f8f8;
  color: #464646;
  padding: 8px;
  border-radius: 2px;
  width: 100%;
  margin: 7px 0;
  -webkit-transition: .3s ease-out;
  transition: .3s ease-out;
}
.selected {
  text-align: center;
  background-color: #1bbdda;
  color: #ffffff;
  padding: 8px;
  border-radius: 2px;
  width: 100%;
  margin: 7px 0;
  -webkit-transition: .3s ease-out;
  transition: .3s ease-out;
}

/* content */
.conteststy{
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 999999999999;
}
.conteststy1{
  position: fixed;
  bottom: 50%;
  /* right: -8px; */
  right: -135px;
  z-index: 999999999999;
}
.conteststy1:hover{
  position: fixed;
  bottom: 50%;
  right: -8px;
  z-index: 999999999999;
}
.btndirection{
  writing-mode: vertical-rl;
  text-orientation: mixed;
  background-color:#24bcef;
  color: #fff;
  font-weight: 600;
  padding: 10px;
}

/* 
const YourComponent = () => {
  const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const buttonStyle = {
    background: '#24bcef',
    color: '#fff',
    writingMode: 'vertical-rl',
    textOrientation: 'mixed',
    height: '100%',
  };

  return (
    <div style={divStyle}>
      <div className="conteststy1">
        <button style={buttonStyle} className="btn btn">
          Apply Loan
        </button>
      </div>
    </div> */

    .bgbefore {
      background-image: radial-gradient( circle at bottom right, #24bded9c 0, #28bdee61 20%, #26bdea73 20%, #7ed7f5bf 40%, #a6e4f7d4 40%, #84daf4e8 60%, #7dd7f4b5 60%, #85dbf4d1 80%, #7dd7f5cc 80%, #a5ed9266 100% );
      opacity: 0.7;
      /* z-index: 1; */
    }

    
.cat{
  margin: 4px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
}

.cat label {
  float: left; 
  line-height: 2.0em;
    width: 4em;
    height: 2em;
    cursor:pointer;
}

.cat label span {
  text-align: center;
  padding: 3px 0;
  display: block;
}

.cat label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
/* selects all of the text within the input element and changes the color of the text */
.cat label input + span{color: #000000;}


/* This will declare how a selected input will look giving generic properties */
.cat input:checked + span {
    color: #ffffff;
    text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}
.sports input:checked + span{background-color: #25bcec;}


    