@media only screen and (max-width:1380px) {
    .hero-section {
        height: 100vh
    }
}

@media only screen and (max-width:1200px) {
    .service-card {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 35px;
        padding: 60px 0
    }

    .ct1280 .container,
    .ct1280 .container-lg,
    .ct1280 .container-md,
    .ct1280 .container-sm,
    .ct1280 .container-xl {
        max-width: 1170px
    }

    .blur-bg {
        width: 350px;
        height: 350px
    }

    .partnerlogo {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 30px
    }

    .steps-div {
        padding: 30px 20px;
        border-radius: 20px
    }
}

@media only screen and (max-width:992px) {
    .header-heading-1 {
        text-align: center
    }

    .th2 .icon-bar {
        background-color: #000
    }

    .hero-feature {
        grid-template-columns: auto auto;
        grid-gap: 20px;
        margin-top: 30px;
        justify-content: center
    }

    .service-card {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 35px;
        padding: 60px 0
    }

    .whyus {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 20px
    }

    .m-mt60 {
        margin-top: 60px
    }

    .img-box {
        text-align: center
    }

    .itm-media-object .media-body {
        margin-left: 15px
    }

    .pr50 {
        padding-right: 0
    }

    h1 {
        font-size: 40px;
        line-height: 50px
    }

    h2 {
        font-size: 35px;
        line-height: 44px
    }

    h3 {
        font-size: 25px;
        line-height: 34px
    }

    h4 {
        font-size: 18px;
        line-height: 26px
    }

    h5 {
        font-size: 16px;
        line-height: 24px
    }

    h6 {
        font-size: 14px;
        line-height: 22px
    }

    p {
        font-size: 16px;
        line-height: 26px
    }

    .m-mt30 {
        margin-top: 30px
    }

    .img-box1 img {
        border-radius: 20px 20px 20px 20px
    }

    .m-text-c {
        text-align: center
    }

    .m-m0 {
        margin: 0 !important
    }

    .hide-desk {
        display: block
    }

    .hide-mob {
        display: none
    }

    .sticky .top-header .nav-link {
        font-weight: 600;
        font-size: 17px;
        color: #333
    }

    .top-header .nav-link {
        font-weight: 600;
        font-size: 17px;
        color: #333
    }

    .top-header .nav-link.active {
        color: #333
    }

    .top-header .nav-link.active:after {
        background: #333
    }

    .fixed-top {
        height: auto
    }

    .mt30a {
        margin-top: 0
    }

    .navbar-collapse {
        margin: 10px 0 0;
        background: #fff;
        padding: 10px 16px
    }

    .sticky .navbar-collapse {
        padding: 0
    }

    .top-header {
        min-height: auto
    }

    .fixed-top {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .header-heading h1 {
        font-size: 40px;
        line-height: 48px
    }

    .header-heading {
        margin: 0;
        padding: 0;
        text-align: center
    }

    .pad-tb {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .form-block {
        margin: 0 0 30px
    }

    .hero-section {
        padding-top: 110px;
        padding-bottom: 100px;
        height: auto
    }

    .top-header .nav-link {
        font-size: 16px;
        padding: 8px 0
    }

    .cta-heading-2 {
        text-align: center;
        margin: 0 auto 30px;
        display: block
    }
}

@media only screen and (max-width:767px) {
    .modal .modal-dialog-aside {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        right: 0
    }

    .modal .modal-dialog-aside .modal-body {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 0
    }

    .service-card {
        grid-gap: 15px;
        padding: 60px 0
    }

    .btn-rd {
        width: 100%
    }

    .itm-media-object .media .img-ab- {
        width: 55px
    }

    .common-heading h2 {
        font-size: 30px
    }

    .hero-section .form-block {
        margin: 30px 0 0
    }

    .header-right ul {
        padding: 0
    }

    .pl0 {
        padding-left: 15px !important
    }

    .cta-heading-2 h2 {
        font-size: 30px;
        text-align: center
    }

    .common-heading h3 {
        font-size: 20px;
        margin-bottom: 20px
    }

    .cta-heading p {
        text-align: center;
        line-height: 1.3
    }

    .cta {
        width: 100%
    }

    .common-heading p {
        max-width: 100%
    }

    .feature-image {
        margin: 20px 0 0
    }
}

@media only screen and (max-width:375px) {
    .header-heading h1 {
        font-size: 35px;
        line-height: 44px
    }

    .mobile-btn span,
    .btn-call i {
        display: none
    }
}